import styled from 'styled-components';
import StripesIcon from './assets/img/handle.png';

export const StripesButtonWrapper = styled.div`
  width: 50px;
  height: 50px;
  background: url(${StripesIcon}) no-repeat;
  background-position: center;
  background-size: 80%;
  background-color: rgb(200, 200, 200);
  position: absolute;
  right: 20px;
  top: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid gray;

  @media (max-width: 576px) and (orientation: portrait) {
    top: 100px;
  }

  @media (max-height: 700px) and (orientation: landscape) {
    top: 80px;
  }
`;
