import styled from 'styled-components/macro';

export const CanvasWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
    outline: none;
    background: transparent;
  }

  .background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      90deg,
      rgba(140, 140, 140, 1) 0%,
      rgba(200, 200, 200, 1) 50%,
      rgba(140, 140, 140, 1) 100%
    );
  }
`;
