import { Engine } from 'react-babylonjs';
import AnimationButton from '../components/Buttons/AnimationButton/AnimationButton';
import StripesButton from '../components/Buttons/StripesButton/StripesButton';
import LabelButton from '../components/Buttons/LabelButton/LabelButton';
import Bag from '../scenes/Bag/Bag';
import { CanvasWrapper } from './RenderingCanvas.css';
import { useState } from 'react';
import LabelEditor from '../components/LabelEditor/LabelEditor';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';

export default function RenderingCanvas() {
  const [isAnimationOpen, setIsAnimationOpen] = useState<boolean>(false);
  const [isStripeHidden, setIsStripeHidden] = useState<boolean>(false);
  const [label, setLabel] = useState<File | null>(null);
  const [texture, setTexture] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function handleSave(image: string) {
    setTexture(image);
  }

  return (
    <CanvasWrapper>
      {isLoading && <LoadingScreen />}

      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Bag
          isAnimationOpen={isAnimationOpen}
          isStripeHidden={isStripeHidden}
          texture={texture}
          setIsLoading={setIsLoading}
        />
      </Engine>
      <div className="background"></div>
      <AnimationButton isAnimationOpen={isAnimationOpen} setIsAnimationOpen={setIsAnimationOpen} />
      <StripesButton isStripeHidden={isStripeHidden} setIsStripeHidden={setIsStripeHidden} />
      <LabelButton setLabel={setLabel} />
      {label && <LabelEditor label={label} setLabel={setLabel} onSave={handleSave} />}
      {/* {texture && <img src={texture} style={{ position: 'absolute', left: 0, top: 0, width: '300px' }}></img>} */}
    </CanvasWrapper>
  );
}
