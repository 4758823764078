import { StripesButtonWrapper } from './StripesButton.css';

interface StripesButtonProps {
  isStripeHidden: boolean;
  setIsStripeHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function StripesButton({ isStripeHidden, setIsStripeHidden }: StripesButtonProps) {
  const handleStripesClick = () => {
    setIsStripeHidden(!isStripeHidden);
  };

  return <StripesButtonWrapper onClick={handleStripesClick}></StripesButtonWrapper>;
}
