import React, { useState, useRef, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { LabelEditorWrapper } from './LabelEditor.css';
import type { ReactCropperElement } from 'react-cropper';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ColorPicker from './ColorPicker';

interface LabelEditorProps {
  label: File | null;
  setLabel: React.Dispatch<React.SetStateAction<File | null>>;
  onSave: (image: string) => void;
}

export default function LabelEditor({ label, setLabel, onSave }: LabelEditorProps) {
  const [originalImage, setOriginalImage] = useState<string | null>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [colorPickerEnabled, setColorPickerEnabled] = useState<boolean>(false);
  const [fillColor, setFillColor] = useState<string>('#000');

  useEffect(() => {
    const cropViewBox = document.getElementsByClassName('cropper-view-box')[0] as HTMLSpanElement;
    if (!cropViewBox) return;
    cropViewBox.style.backgroundColor = fillColor;
  }, [fillColor]);

  useEffect(() => {
    if (!label) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target?.result;
      if (fileContent) {
        if (label.type === 'application/pdf') {
          const pdfTask = pdfjs.getDocument(fileContent);

          pdfTask.promise.then((pdf) => {
            pdf.getPage(1).then((page) => {
              if (!canvasRef.current) return;
              const scale = 1;
              const viewport = page.getViewport({ scale });
              const context = canvasRef.current.getContext('2d');
              context?.rotate(Math.PI);
              canvasRef.current.width = viewport.width;
              canvasRef.current.height = viewport.height;

              if (!context) return;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              page.render(renderContext).promise.then(() => {
                if (!canvasRef.current) return;

                setOriginalImage(canvasRef.current?.toDataURL());
              });
            });
          });
        } else {
          setOriginalImage(fileContent.toString());
        }
      }
    };
    reader.readAsDataURL(label);
  }, [label]);

  function save() {
    const cropperElement = cropperRef.current;
    if (!cropperElement) return;
    const cropper = cropperElement.cropper;
    const editedImage = cropper.getCroppedCanvas({ fillColor: fillColor }).toDataURL('image/png');
    onSave(editedImage);
    setLabel(null);
  }

  function zoomIn() {
    const cropperElement = cropperRef.current;
    if (!cropperElement) return;
    const cropper = cropperElement.cropper;
    cropper.zoom(0.1);
  }

  function zoomOut() {
    const cropperElement = cropperRef.current;
    if (!cropperElement) return;
    const cropper = cropperElement.cropper;
    cropper.zoom(-0.1);
  }

  function rotateCW() {
    const cropperElement = cropperRef.current;
    if (!cropperElement) return;
    const cropper = cropperElement.cropper;
    cropper.rotate(90);
  }

  function rotateCCW() {
    const cropperElement = cropperRef.current;
    if (!cropperElement) return;
    const cropper = cropperElement.cropper;
    cropper.rotate(-90);
  }

  return (
    <LabelEditorWrapper className="label-editor">
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      {originalImage && (
        <Cropper
          className="editor-cropper"
          ref={cropperRef}
          src={originalImage}
          viewMode={0}
          guides={false}
          cropBoxResizable={false}
          cropBoxMovable={false}
          dragMode="move"
          zoomOnWheel={true}
          zoomOnTouch={true}
          aspectRatio={1.4}
          toggleDragModeOnDblclick={false}
        />
      )}
      <h1 className="editor-title">LABEL EDITOR</h1>
      <h1 className="editor-description">SELECT THE LABEL AREA THAT YOU WANT TO UPLOAD</h1>
      <div className="controls">
        <div className="btn-controls zoomin-control" onClick={() => zoomIn()}></div>
        <div className="btn-controls zoomout-control" onClick={() => zoomOut()}></div>
        <div className="btn-controls rotatecw-control" onClick={() => rotateCW()}></div>
        <div className="btn-controls rotateccw-control" onClick={() => rotateCCW()}></div>
        <div className="btn-controls colorpicker-control" onClick={() => setColorPickerEnabled(true)}></div>
      </div>
      <div className="btn-upload" onClick={() => save()}>
        UPLOAD
      </div>
      <div
        className="btn-close"
        onClick={() => {
          setOriginalImage(null);
          setLabel(null);
        }}
      ></div>
      {colorPickerEnabled && (
        <ColorPicker
          setFillColor={setFillColor}
          setColorPickerEnabled={setColorPickerEnabled}
          originalImage={originalImage}
        />
      )}
    </LabelEditorWrapper>
  );
}
