import { LoadingScreenWrapper } from './LoadingScreen.css';
import LoadingImage from './assets/img/loading.png';

export default function LoadingScreen() {
  return (
    <LoadingScreenWrapper>
      <img src={LoadingImage} alt=""></img>
    </LoadingScreenWrapper>
  );
}
