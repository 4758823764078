import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';

export default createGlobalStyle`

${reset};

  html {
    overflow: hidden;    
    font-family: Arial, Helvetica, sans-serif;    
  }
  * {
    box-sizing: border-box;  
 
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
                                  
  } 

  #inspector-host {
      position: absolute !important;
      z-index: 5000 !important;
      right: 0;
      top:0;
  }

  #scene-explorer-host {
    position: absolute !important;
    z-index: 5000 !important;
    left: 0;
    top:0;
}
  
`;
