import styled from 'styled-components';

import OpenBagIcon from './assets/img/openbag.png';

export const AnimationButtonWrapper = styled.div`
  width: 50px;
  height: 50px;
  background: url(${OpenBagIcon}) no-repeat;
  background-position: center;
  background-size: 60%;
  background-color: rgb(200, 200, 200);
  position: absolute;
  right: 20px;
  top: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid gray;

  @media (max-width: 576px) and (orientation: portrait) {
    top: 30px;
  }

  @media (max-height: 700px) and (orientation: landscape) {
    top: 20px;
  }
`;
