import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
// import type { PhotoshopPickerStylesProps } from 'react-color/lib/components/photoshop/Photoshop';
// import type { DraggableData, DraggableEvent } from 'react-draggable';
// import Draggable from 'react-draggable';
// import type { Classes } from 'reactcss';
import { ColorPickerWrapper } from './ColorPicker.css';
import { isMobile } from 'react-device-detect';

interface ColorPickerProps {
  setFillColor: React.Dispatch<React.SetStateAction<string>>;
  setColorPickerEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  originalImage: string | null;
}

function rgbaToHex(rgba: string): string {
  const rgbaValues = rgba.slice(5, -1).split(',');
  const r = parseInt(rgbaValues[0]);
  const g = parseInt(rgbaValues[1]);
  const b = parseInt(rgbaValues[2]);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  const hex = `#${hexR}${hexG}${hexB}`;

  return hex;
}

function ColorPicker(props: ColorPickerProps) {
  const { setFillColor, setColorPickerEnabled, originalImage } = props;
  //  const pickerRef = useRef<HTMLDivElement>(null);
  const colorSamplerCanvasRef = useRef<HTMLCanvasElement>(null);

  const [colorPickerColor, setColorPickerColor] = useState<string>('#000');
  const [previewFillColor, setPreviewFillColor] = useState<string>('#000');
  const [isColorPickerMethod, setIsColorPickerMethod] = useState<boolean>(true);
  const [imageForSampling, setImageForSampling] = useState<string | null>(null);
  const pointerDown = useRef<boolean>(false);

  useEffect(() => {
    const viewBox = document.getElementsByClassName('cropper-view-box')[0];
    if (!viewBox) return;
    const backgroundColor = window.getComputedStyle(viewBox).backgroundColor;

    setColorPickerColor(backgroundColor);
    setPreviewFillColor(backgroundColor);

    setImageForSampling(originalImage);
  }, []);

  useEffect(() => {
    if (!imageForSampling || isColorPickerMethod) return;
    const image = new Image();
    image.onload = () => {
      const canvas = colorSamplerCanvasRef.current;
      if (!canvas) return;
      const aspectRatio = image.width / image.height;
      const dimensionLimit = isMobile ? 200 : 230;

      let canvasWidth = 0;
      let canvasHeight = 0;
      if (aspectRatio >= 1) {
        canvasWidth = dimensionLimit;
        canvasHeight = canvasWidth / aspectRatio;
      } else {
        canvasHeight = dimensionLimit;
        canvasWidth = canvasHeight * aspectRatio;
      }

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };

    image.src = imageForSampling;
  }, [imageForSampling, isColorPickerMethod]);

  const handleEyedropper = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!pointerDown.current) return;
    const canvas = colorSamplerCanvasRef.current;
    if (!canvas) return;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    const ctx = canvas.getContext('2d');
    const imageData = ctx?.getImageData(x, y, 1, 1);
    const pixel = imageData?.data;
    if (!pixel) return;
    const pixelColor = rgbaToHex(`rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`);

    setFillColor(pixelColor);
    setColorPickerColor(pixelColor);
  };

  return (
    // <Draggable handle=".handle" onDrag={handleDrag}>
    <ColorPickerWrapper className="editor-colorpicker">
      <div className="picker-header">Pick fill color from </div>
      <div className="picker-buttons">
        <div className="cp-btn picker" onClick={() => setIsColorPickerMethod(true)}></div>
        <div className="cp-btn sampler" onClick={() => setIsColorPickerMethod(false)}></div>
      </div>

      <div className="function-buttons">
        <button
          className="cpf-btn"
          onClick={() => {
            setPreviewFillColor(colorPickerColor);
            setColorPickerEnabled(false);
          }}
        >
          Accept
        </button>
        <button
          className="cpf-btn"
          onClick={() => {
            setColorPickerColor(previewFillColor);
            setFillColor(previewFillColor);
            setColorPickerEnabled(false);
          }}
        >
          Cancel
        </button>
      </div>

      {isColorPickerMethod ? (
        <ChromePicker
          color={colorPickerColor}
          disableAlpha={true}
          onChange={(color) => {
            setFillColor(color.hex);
            setColorPickerColor(color.hex);
          }}
        ></ChromePicker>
      ) : (
        <canvas
          onMouseDown={(e) => {
            pointerDown.current = true;
            handleEyedropper(e);
          }}
          onMouseUp={() => (pointerDown.current = false)}
          onMouseMove={(e) => handleEyedropper(e)}
          className="color-sampler-canvas"
          ref={colorSamplerCanvasRef}
        />
      )}
    </ColorPickerWrapper>
    // </Draggable>
  );
}

export default ColorPicker;
