import styled from 'styled-components';
import ccw from './assets/icons/90CCW.png';
import cw from './assets/icons/90CW.png';
import crop from './assets/icons/CrossHair.png';
import zoomin from './assets/icons/ZoomIn.png';
import zoomout from './assets/icons/ZoomOut.png';
import close from './assets/icons/close.png';
import colorpicker from './assets/icons/Colorpicker.png';

export const LabelEditorWrapper = styled.div`
  position: absolute;
  width: 900px;
  height: 600px;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-450px, -300px);
  border-radius: 20px;
  display: grid;

  @media (max-width: 576px) and (orientation: portrait) {
    width: 300px;
    height: 500px;
    transform: translate(-150px, -250px);
  }

  @media (max-height: 700px) and (orientation: landscape) {
    width: 500px;
    height: 300px;
    transform: translate(-250px, -150px);
  }

  .editor-cropper {
    width: 550px;
    height: 100%;

    @media (max-width: 576px) and (orientation: portrait) {
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }

  .editor-title {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 576px) and (orientation: portrait) {
      top: 20px;
      left: 20px;
      font-size: 20px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      right: 80px;
      top: 20px;
      font-size: 20px;
    }
  }

  .editor-description {
    width: 230px;
    position: absolute;
    right: 50px;
    top: 100px;
    font-size: 16px;
    font-weight: 400;

    @media (max-width: 576px) and (orientation: portrait) {
      top: 50px;
      font-size: 14px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      right: 30px;
      font-size: 14px;
      width: 200px;
      top: 60px;
    }
  }

  .controls {
    width: 320px;
    height: 70px;
    position: absolute;
    right: 13px;
    top: 50%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) and (orientation: portrait) {
      width: 90%;
      top: 100px;
      justify-content: space-around;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      width: 220px;
      top: 120px;
    }
  }

  .btn-controls {
    width: 50px;
    height: 50px;
    background: white;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;

    @media (max-width: 576px) and (orientation: portrait) {
      width: 40px;
      height: 40px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      width: 40px;
      height: 40px;
    }
  }

  .btn-upload {
    position: absolute;
    bottom: 5%;
    right: 5%;
    background: blue;
    color: white;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    width: 90px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s ease-in;

    @media (max-width: 576px) and (orientation: portrait) {
      width: 70px;
      height: 30px;
      top: 200px;
      font-size: 12px;
      right: 10px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      width: 80px;
      height: 40px;
      font-size: 14px;
    }
  }

  .btn-upload:hover {
    opacity: 1;
  }

  .btn-close {
    position: absolute;
    right: 0;
    top: -20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(${close}) no-repeat;
    background-position: center;
    background-size: 60%;
    background-color: white;
    border: 2px solid lightgray;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in;
  }

  .btn-close:hover {
    transform: rotate(90deg);
  }

  .zoomin-control {
    position: relative;
    background: url(${zoomin}) no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .zoomin-control:after {
    content: 'Zoom In';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 11px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      font-size: 11px;
    }
  }

  .zoomout-control {
    position: relative;
    background: url(${zoomout}) no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .zoomout-control:after {
    content: 'Zoom Out';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 11px;
    }
    @media (max-height: 700px) and (orientation: landscape) {
      font-size: 11px;
    }
  }

  .rotatecw-control {
    position: relative;
    background: url(${cw}) no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .rotatecw-control:after {
    content: 'Rotate CW';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 11px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      font-size: 11px;
    }
  }

  .rotateccw-control {
    position: relative;
    background: url(${ccw}) no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .rotateccw-control:after {
    content: 'Rotate CCW';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 11px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      font-size: 11px;
    }
  }

  .crop-control {
    background: url(${crop}) no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .colorpicker-control {
    position: relative;
    background: url(${colorpicker}) no-repeat;
    background-position: center;
    background-size: 90%;
  }

  .colorpicker-control:after {
    content: 'Fill Color';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 11px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      font-size: 11px;
    }
  }

  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 30px;
    @media (max-width: 576px) and (orientation: portrait) {
      border-radius: 20px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      border-radius: 25px;
    }
  }

  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
    background-color: rgba(0, 0, 0, 1);
  }
`;
