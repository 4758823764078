import styled from 'styled-components';
import UploadIcon from './assets/img/upload.png';

export const LabelButtonWrapper = styled.label`
  width: 80px;
  height: 40px;
  background: url(${UploadIcon}) no-repeat;
  background-position: center;
  background-size: contain;
  background-color: rgb(200, 200, 200);
  position: absolute;
  right: 20px;
  bottom: 50px;
  border: 2px solid grey;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) and (orientation: portrait) {
    bottom: 30px;
  }

  @media (max-height: 700px) and (orientation: landscape) {
    bottom: 20px;
  }
`;
