import { LabelButtonWrapper } from './LabelButton.css';

interface LabelButtonProps {
  setLabel: React.Dispatch<React.SetStateAction<File | null>>;
}
export default function LabelButton({ setLabel }: LabelButtonProps) {
  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check if the uploaded file is an image or a PDF
    const isImage = file.type.startsWith('image/');
    const isPdf = file.type === 'application/pdf';

    if (isImage || isPdf) {
      setLabel(file);
      event.target.value = '';
    } else {
      alert('Please upload an image or a PDF file.');
    }
  }
  return (
    <>
      <LabelButtonWrapper htmlFor="file-upload"></LabelButtonWrapper>
      <input id="file-upload" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
    </>
  );
}
