import { AnimationButtonWrapper } from './AnimationButton.css';

interface AnimationButtonProps {
  isAnimationOpen: boolean;
  setIsAnimationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AnimationButton({ isAnimationOpen, setIsAnimationOpen }: AnimationButtonProps) {
  const handleAnimationClick = () => {
    setIsAnimationOpen(!isAnimationOpen);
  };

  return <AnimationButtonWrapper onClick={handleAnimationClick}>A</AnimationButtonWrapper>;
}
