/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef } from 'react';
import type { AnimationGroup, PBRMaterial, Scene as SceneType } from '@babylonjs/core';
import { CubeTexture } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';
import { Vector3, Color3, Color4 } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { isMobile } from 'react-device-detect';

interface BagProps {
  isAnimationOpen: boolean;
  isStripeHidden: boolean;
  texture: string | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function Bag(props: BagProps) {
  const { isAnimationOpen, isStripeHidden, texture, setIsLoading } = props;

  const sceneRef = useRef<SceneType>();
  const animationGroupsRef = useRef<AnimationGroup[]>();
  const bagMaterialRef = useRef<PBRMaterial>();

  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene) return;
    const openAnimation = scene.getAnimationGroupByName('Open') as AnimationGroup;
    const closeAnimation = scene.getAnimationGroupByName('Closed') as AnimationGroup;
    if (!openAnimation || !closeAnimation) return;

    if (isAnimationOpen) {
      openAnimation.goToFrame(closeAnimation.from);
      openAnimation.play();
    } else {
      closeAnimation.goToFrame(closeAnimation.from);
      closeAnimation.play();
    }
  }, [isAnimationOpen]);

  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene) return;

    const handle = scene.getTransformNodeByName('Handle');
    const handlePart = scene.getMeshByName('Plane.013');

    if (!handle || !handlePart) return;

    handle.setEnabled(!handle.isEnabled());
    handlePart.setEnabled(!handlePart.isEnabled());
  }, [isStripeHidden]);

  useEffect(() => {
    if (!bagMaterialRef.current) return;
    bagMaterialRef.current.albedoTexture = new Texture(texture, sceneRef.current, false, false);
    bagMaterialRef.current.albedoTexture.hasAlpha = true;
    bagMaterialRef.current.useRadianceOverAlpha = false;
    bagMaterialRef.current.albedoColor = new Color3(1, 1, 1);
  }, [texture]);

  const setupScene = ({ scene }: SceneEventArgs) => {
    sceneRef.current = scene;
    animationGroupsRef.current = scene.animationGroups;
    scene.clearColor = new Color4(0, 0, 0, 0);
    scene.onReadyObservable.add(() => {
      setIsLoading(false);

      // scene.debugLayer.show();
    });
  };

  const setupBag = () => {
    const scene = sceneRef.current;
    if (!scene) return;
    scene.stopAllAnimations();
    const environmentTexture = new CubeTexture('./assets/img/environmentSpecular.env', scene);
    scene.environmentTexture = environmentTexture;

    const handle = scene.getTransformNodeByName('Handle');
    const handlePart = scene.getMeshByName('Plane.013');

    handle?.setEnabled(false);
    handlePart?.setEnabled(false);

    const bagMaterial = scene.getMaterialByName('Reflective plastic') as PBRMaterial;
    bagMaterial.roughness = 0.1;
    bagMaterial.metallic = 0;

    bagMaterialRef.current = bagMaterial;
  };

  return (
    <Scene
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 0, 0)}
        alpha={Math.PI / 2 - 0.5}
        beta={Math.PI / 3 + 0.2}
        radius={isMobile ? 30 : 20}
        lowerRadiusLimit={10}
        upperRadiusLimit={60}
        minZ={0.01}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
        intensity={0.6}
      />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        <Model
          name="bag"
          sceneFilename="bag.glb"
          rootUrl="./assets/mesh/"
          onCreated={() => {
            setupBag();
          }}
        ></Model>
      </Suspense>
    </Scene>
  );
}

export default Bag;
