import styled from 'styled-components';

import eyedropper from './assets/icons/eyedropper.png';
import picker from './assets/icons/picker.png';

export const ColorPickerWrapper = styled.div`
  width: 400px;
  height: 360px;
  background: white;
  position: absolute;
  display: flex;
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.75);

  @media (max-width: 576px) and (orientation: portrait) {
    width: 300px;
    height: 430px;
    position: absolute;
    top: -100px;
  }

  @media (max-height: 700px) and (orientation: landscape) {
    height: 300px;
    right: -100px;
  }

  .picker-header {
    position: absolute;
    top: 12px;
    font-size: 20px;

    @media (max-width: 576px) and (orientation: portrait) {
      font-size: 16px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      right: 20px;
      font-size: 18px;
      top: 50px;
    }
  }

  .picker-buttons {
    width: 200px;
    height: 40px;
    top: 45px;
    display: flex;
    justify-content: space-evenly;
    position: absolute;

    @media (max-width: 576px) and (orientation: portrait) {
      width: 130px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      right: 20px;
      width: 140px;
      top: 90px;
    }

    .cp-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid black;
    }

    .sampler {
      position: relative;
      background: url(${eyedropper}) no-repeat;
      background-position: center;
      background-size: 70%;
    }

    .sampler:after {
      content: 'Image';
      width: 200%;
      height: 100%;
      position: absolute;
      left: -50%;
      top: 100%;
      font-size: 12px;
      text-align: center;
      margin-top: 5px;
    }

    .picker {
      position: relative;
      background: url(${picker}) no-repeat;
      background-position: 55% 50%;
      background-size: 70%;
    }

    .picker:after {
      content: 'Colorpicker';
      width: 200%;
      height: 100%;
      position: absolute;
      left: -50%;
      top: 100%;
      font-size: 12px;
      text-align: center;
      margin-top: 5px;
    }
  }

  .function-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60px;
    height: 80px;
    right: 15px;
    top: 15px;
    position: absolute;

    @media (max-width: 576px) and (orientation: portrait) {
      flex-direction: row;
      width: 300px;
      justify-content: space-evenly;
      left: 0;
      height: 30px;
      top: unset;
      bottom: 15px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      flex-direction: row;
      justify-content: space-evenly;
      width: 150px;
      bottom: 40px;
      height: 30px;
      top: unset;
    }

    .cpf-btn {
      width: 60px;
      height: 30px;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .color-sampler-canvas {
    bottom: 20px;
    position: absolute;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);

    @media (max-width: 576px) and (orientation: portrait) {
      bottom: 70px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      left: 20px;
      bottom: 40px;
    }
  }
  .chrome-picker {
    position: absolute;
    bottom: 20px;

    @media (max-width: 576px) and (orientation: portrait) {
      bottom: 70px;
    }

    @media (max-height: 700px) and (orientation: landscape) {
      left: 20px;
      width: 200px !important;
      bottom: 40px;
    }
  }
`;
